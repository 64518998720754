@import "./_mantine.scss";
.filterCard {
  width: 100%;
  height: fit-content;
  overflow: auto;
  background-color: white;
  padding: 16px 0 0 0;
}

.checkboxRoot {
  padding-block: var(--mantine-spacing-sm);
}

.checkboxIcon {
  color: white;
}

.checkboxIconChecked {
  color: var(--mantine-color-lime-3);
}
