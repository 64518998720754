@import "./_mantine.scss";
.header {
  cursor: pointer;
}
.headerActive {
  text-decoration: underline;
}
.icon {
  margin-left: 4px;
}
.iconActive {
  transform: rotate(180deg);
}
.centerAlign {
  text-align: center;
}
.rightAlign {
  text-align: right;
}
.leftAlign {
  text-align: left;
}
