@import "./_mantine.scss";
.drawerContent {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: #f7f7f7;
  height: auto;
  max-height: 90vh;
  overflow-y: scroll;
}

.innerDrawerContent {
  background-color: white;
  height: auto !important;
}

.drawerHeader {
  padding-top: var(--mantine-spacing-lg);
  padding-right: var(--mantine-spacing-lg);
}

.drawerCloseButton svg {
  width: 24px;
  height: 24px;
  stroke-width: 2;
  color: var(--mantine-color-blue-8);
}

.closeButton {
  border: none;
  position: absolute;
  right: 20px;
  top: 35px;
  color: var(--mantine-color-gray-6);
}

.controlLabel {
  background: transparent;
  color: var(--mantine-color-gray-5);
  padding: 4px var(--mantine-spacing-lg);
  height: 40px;
}

.controlLabel[data-active] {
  color: var(--mantine-color-white) !important;
  background: var(--mantine-color-blue-6);
}

.control {
  height: 40px;
}

.controlActive {
  background-color: var(--mantine-color-blue-6);
}

.controlRoot {
  box-shadow: -4px -1px 41px rgba(0, 0, 0, 0.13);
  margin-bottom: var(--mantine-spacing-xl);
  margin-top: var(--mantine-spacing-sm);
  width: 100%;
  background-color: var(--mantine-color-gray-1);
}

.controlRootHidden {
  display: none;
}

.buttonWrapper {
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: white;
  padding: 16px 15px;
  margin: 0 -16px -16px -16px;
  box-shadow: 0px -20px 20px 10px rgba(211, 211, 211, 0.2);
}
