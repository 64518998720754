@import "./_mantine.scss";
.filterButton {
  color: var(--mantine-color-gray-6);
  background-color: #fff;
  border-color: var(--mantine-color-gray-4);
  border-radius: 4px;
  padding-left: var(--mantine-spacing-xs);
  padding-right: var(--mantine-spacing-xs);
  width: 174px;

  &:hover {
    color: var(--mantine-color-gray-6);
    background-color: var(--mantine-color-gray-1);
  }
  @media (max-width: $mantine-breakpoint-sm) {
    width: 100%;
  }
}
.dataExpanded {
  outline: 3px solid var(--mantine-color-blue-2);
  border: 1px solid var(--mantine-color-blue-2) !important;
}

.filterButtonLabel {
  font-weight: 700;
  flex-grow: 1;
}

.buttonIconRotate {
  &[data-position='right'] {
    transform: rotate(180deg);
  }
}

.filterButtonInner {
  width: 100%;
  display: flex;
  justify-content: start;
}

.filterSelected {
  background-color: var(--mantine-color-blue-1);
  border: 1px solid var(--mantine-color-blue-5);
  &:hover {
    background-color: var(--mantine-color-blue-2);
  }
}

.checkboxesWrapper {
  gap: 0;
}

.checkboxRoot {
  &[data-checked='true'] {
    label {
      font-weight: 700;
    }
    background-color: var(--mantine-color-blue-1);
  }
}
.checkbox {
  border-radius: 2px;
  border: 2px solid var(--mantine-color-blue-5);
  background-color: white;
}
.checkboxIndeterminate {
  background-color: var(--mantine-color-blue-5);
  border-color: var(--mantine-color-blue-5);
}
.checkboxIcon {
  color: var(--mantine-color-lime-5);
}
.checkboxBody {
  padding-block: var(--mantine-spacing-xs);
  padding-inline: 12px;
  justify-content: space-between;
  &:hover {
    background-color: var(--mantine-color-gray-1);
  }
}

.checkboxBodyIndeterminate {
  label {
    font-weight: 700;
  }
  background-color: var(--mantine-color-blue-1);
}

.checkboxLabel {
  font-weight: 600;
  color: var(--mantine-color-blue-8);
  font-size: var(--mantine-font-size-sm);
}

.dataContainer {
  background-color: var(--mantine-color-gray-1);
  padding-inline: var(--mantine-spacing-md);

  @media (min-width: $mantine-breakpoint-sm) {
    background-color: white;
    padding-top: var(--mantine-spacing-xs);
    padding-left: var(--mantine-spacing-xs);
  }
}

.floatingDropdown {
  position: absolute;
  top: 100%;
  margin-top: var(--mantine-spacing-xs);
  z-index: 4;
  max-height: 220px;
  width: 100%;
  overflow: scroll;
  background-color: white;
  box-shadow: 0px 4px 54px 0px #0b174e29;
  border-radius: 4px;
}
